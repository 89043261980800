import React from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, Button } from '@mui/material';

const HBARVote: React.FC = () => {
  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        HBAR Vote
      </Typography>
      
      <Paper sx={{ p: 3, mb: 3, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h6" gutterBottom>
          Current Proposals
        </Typography>
        <List>
          {[1, 2, 3].map((proposal) => (
            <ListItem key={proposal} sx={{ mb: 2, backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
              <ListItemText 
                primary={`Proposal ${proposal}`} 
                secondary={`Description for proposal ${proposal}`}
              />
              <Button variant="contained" color="primary" sx={{ ml: 2 }}>
                Vote
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Paper sx={{ p: 3, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h6" gutterBottom>
          Voting History
        </Typography>
        {/* Add voting history here */}
      </Paper>
    </Box>
  );
};

export default HBARVote;

