import { Campaign } from '../types/Campaign';

type MongoDate = {
  $date: {
    $numberLong: string;
  };
};

// Type guard to check if the value is a MongoDate
const isMongoDate = (value: any): value is MongoDate => {
  return typeof value === 'object' && 
         value !== null && 
         '$date' in value && 
         typeof value.$date === 'object' &&
         value.$date !== null &&
         '$numberLong' in value.$date;
};

export const getTimeLeft = (endTime: Campaign['endTime'] | string | Date): string => {
  try {
    let endTimeMs: number;

    // Handle MongoDB date format
    if (isMongoDate(endTime)) {
      endTimeMs = parseInt(endTime.$date.$numberLong);
    }
    // Handle string format
    else if (typeof endTime === 'string') {
      endTimeMs = new Date(endTime).getTime();
    }
    // Handle Date object
    else if (endTime instanceof Date) {
      endTimeMs = endTime.getTime();
    }
    // Handle invalid input
    else {
      console.error('Invalid endTime format:', endTime);
      return 'Invalid date';
    }

    const now = Date.now();
    const diff = endTimeMs - now;

    if (diff < 0) return 'Ended';

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  } catch (error) {
    console.error('Error calculating time left:', error);
    return 'Invalid date';
  }
}; 