import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: '"Styrene A Web", "Helvetica Neue", Sans-Serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ff004e', // Vibrant red color
      light: '#ff3371', // Lighter shade for hover effects
      dark: '#cc003e', // Darker shade for pressed states
    },
    background: {
      default: '#222222', // Dark background
      paper: 'rgba(255, 255, 255, 0.05)', // Slightly transparent white for content areas
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ff004e', // Vibrant red for the app bar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Square buttons
          textTransform: 'none', // Prevents automatic uppercase transformation
        },
        contained: {
          backgroundColor: '#ff004e',
          '&:hover': {
            backgroundColor: '#ff3371', // Lighter shade for hover effect
          },
          '&:active': {
            backgroundColor: '#cc003e', // Darker shade for pressed state
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          backdropFilter: 'blur(10px)',
        },
      },
    },
  },
});
