import React from 'react';
import { Typography, Box, Paper, TextField, Button } from '@mui/material';

const Loudspeaker: React.FC = () => {
  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        Loudspeaker
      </Typography>
      
      <Paper sx={{ p: 3, mb: 3, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h6" gutterBottom>
          Broadcast a Message
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Enter your message here"
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary">
          Broadcast
        </Button>
      </Paper>

      <Paper sx={{ p: 3, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h6" gutterBottom>
          Recent Broadcasts
        </Typography>
        {/* Add list of recent broadcasts here */}
      </Paper>
    </Box>
  );
};

export default Loudspeaker;

