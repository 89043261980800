import { AppBar, Button, Toolbar, Typography, Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import MouthLogo from "../assets/the_mouth_logo_pink_and_white_border.png";
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import { WalletSelectionDialog } from './WalletSelectionDialog';
import { Link } from 'react-router-dom';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function NavBar() {
  const [open, setOpen] = useState(false);
  const { accountId, walletInterface } = useWalletInterface();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const hasToken = !!localStorage.getItem('authToken');
    return hasToken;
  });

  const handleConnect = async () => {
    if (accountId) {
      walletInterface.disconnect();
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (accountId) {
      setOpen(false);
    }
  }, [accountId]);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authToken = localStorage.getItem('authToken');
      
      if (authToken && accountId) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/authenticate`, { token: authToken });
          setIsAuthenticated(response.data.success);
        } catch (error) {
          console.error('Auth check failed:', error);
          localStorage.removeItem('authToken');
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, [accountId]);

  const handleAuthenticate = async () => {
    if (!walletInterface) return;
    
    try {
      const token = await walletInterface.signMessage('Authenticate');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/authenticate`, { token });
      
      if (response.data.success) {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Authentication failed:', error);
    }
  };

  return (
    <AppBar position='sticky' sx={{ backgroundColor: '#ff004e' }}>
      <Toolbar sx={{ 
        justifyContent: 'space-between',
        minHeight: isMobile ? '56px' : '64px',
        padding: isMobile ? '0 8px' : '0 24px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={MouthLogo} 
            alt='The Mouth Logo' 
            style={{ 
              height: isMobile ? '24px' : '32px', 
              width: 'auto', 
              marginRight: isMobile ? '6px' : '10px' 
            }}
          />
          {!isMobile && (
            <Typography 
              variant="h6" 
              sx={{ 
                color: 'white', 
                fontWeight: 'bold',
                fontSize: '1.1rem'
              }}
            >
              The Mouth
            </Typography>
          )}
        </Box>

        {!isMobile && (
          <Box sx={{
            position: 'absolute',
            left: '200px',
            top: 0,
            height: '100%',
            width: '2px',
            borderLeft: '1px solid white',
          }} />
        )}

        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          marginRight: 'auto', 
          marginLeft: isMobile ? 1 : 3 
        }}>
          <Button 
            color="inherit" 
            component={Link} 
            to="/ser" 
            sx={{ 
              fontSize: isMobile ? '0.8rem' : '0.9rem',
              padding: isMobile ? '4px 8px' : '6px 12px',
            }}
          >
            SER
          </Button>
        </Box>

        <Stack 
          direction="row" 
          spacing={isMobile ? 1 : 2} 
          alignItems="center"
        >
          {accountId && (
            <Button
              variant="outlined"
              onClick={handleAuthenticate}
              disabled={isAuthenticated}
              sx={{ 
                color: 'white',
                borderColor: 'white',
                borderRadius: '20px',
                fontSize: isMobile ? '0.75rem' : '0.9rem',
                padding: isMobile ? '2px 8px' : '4px 12px',
                backgroundColor: isAuthenticated ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                '&:hover': {
                  borderColor: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
                '&:disabled': {
                  color: 'rgba(255, 255, 255, 0.5)',
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                minWidth: isMobile ? 'auto' : '120px',
              }}
              startIcon={isAuthenticated ? 
                <CheckCircleOutlineIcon sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }} /> : 
                <LockPersonOutlinedIcon sx={{ fontSize: isMobile ? '1rem' : '1.2rem' }} />
              }
            >
              {isAuthenticated ? 
                (isMobile ? 'Auth' : 'Authenticated') : 
                (isMobile ? 'Auth' : 'Authenticate')}
            </Button>
          )}
          <Button
            variant='outlined'
            onClick={handleConnect}
            sx={{
              color: 'white',
              borderColor: 'white',
              borderRadius: '20px',
              fontSize: isMobile ? '0.75rem' : '0.9rem',
              padding: isMobile ? '2px 8px' : '4px 12px',
              '&:hover': {
                borderColor: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              maxWidth: isMobile ? '120px' : 'none',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {accountId ? 
              (isMobile ? `${accountId.slice(0, 6)}...` : `Connected: ${accountId}`) : 
              'Connect Wallet'}
          </Button>
        </Stack>
      </Toolbar>
      <WalletSelectionDialog open={open} setOpen={setOpen} onClose={() => setOpen(false)} />
    </AppBar>
  );
}
