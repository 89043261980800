import React from 'react';
import { Typography, Box, Paper, Card, CardContent, CardActions, Button, Grid } from '@mui/material';

const Bounties: React.FC = () => {
  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        Bounties
      </Typography>
      
      <Grid container spacing={3}>
        {[1, 2, 3, 4].map((bounty) => (
          <Grid item xs={12} sm={6} md={4} key={bounty}>
            <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Bounty {bounty}
                </Typography>
                <Typography variant="body2">
                  Description for Bounty {bounty}. Complete this task to earn rewards.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  View Details
                </Button>
                <Button size="small" color="secondary">
                  Claim Bounty
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Bounties;

