import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Paper, Grid, Button, Chip, LinearProgress, CircularProgress, Container, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import XIcon from '@mui/icons-material/X';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import serBackground from '../assets/ser_bg.jpg';
import { Campaign, Tweet, UserInteraction, UserCampaignData, UserData } from '../types/Campaign';
import { styled } from '@mui/material/styles';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import CampaignTweets from '../components/CampaignTweets';
import { getTimeLeft } from '../utils/timeUtils';

const NetworkLogo = styled('img')({
  width: 16,
  height: 16,
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 4,
});

const StyledTableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  color: '#ff004e',
  borderBottom: '1px solid #ff004e',
  padding: '8px 16px', // Reduced padding
});

const StyledTableCell = styled(TableCell)({
  color: '#ff004e',
  borderBottom: '1px solid lightgrey',
  padding: '8px 16px', // Reduced padding
});

const CampaignDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(true);
  const { accountId } = useWalletInterface();
  const [userCampaignData, setUserCampaignData] = useState<UserCampaignData | null>(null);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [claimError, setClaimError] = useState<string | null>(null);
  const [claimTxId, setClaimTxId] = useState<string | null>(null);
  const [leaderboard, setLeaderboard] = useState<Array<{
    accountId: string;
    displayName: string;
    score: number;
  }>>([]);
  const [leaderboardPage, setLeaderboardPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const [userInteractions, setUserInteractions] = useState<UserInteraction[]>([]);
  const [isOptedIn, setIsOptedIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      if (!id) return;
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns/${id}`);
        setCampaign(response.data);
      } catch (error) {
        console.error('Error fetching campaign:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [id]);

  useEffect(() => {
    // Reset states when campaign ID changes
    setIsOptedIn(false);
    setUserCampaignData(null);
    setUserInteractions([]);
    setIsClaimable(false);
    setClaimError(null);
    setClaimTxId(null);
  }, [id]); // Only depend on the campaign id

  const fetchUserData = async () => {
    if (!accountId || !id) {
      console.log('Missing accountId or id:', { accountId, id });
      return;
    }
    
    try {
      // First get the user data to check opt-in status
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${accountId}`);
      
      // Check if campaign exists in optedInCampaigns
      const optedInCampaigns = userResponse.data.optedInCampaigns || [];
      console.log('Checking opt-in status:', {
        optedInCampaigns,
        campaignToCheck: id,
        campaignKey: `campaign_${id}`
      });

      // Direct array inclusion check
      const hasOptedIn = optedInCampaigns.includes(id);
      
      console.log('Opt-in determination:', {
        isInOptedList: hasOptedIn,
        currentCampaignId: id,
        optedInCampaigns
      });
      
      setIsOptedIn(hasOptedIn);

      // Then get campaign specific data
      const campaignResponse = await axios.get<UserData>(
        `${process.env.REACT_APP_API_URL}/api/users/${accountId}/campaigns/${id}`
      );
      
      const campaignKey = `campaign_${id}`;
      const campaignData = campaignResponse.data.campaigns_data?.[campaignKey];
      
      if (campaignData) {
        console.log('Found campaign data:', {
          hasData: true,
          claimStatus: campaignData.claimStatus,
          interactions: campaignData.interactions?.length
        });
        setUserCampaignData(campaignData);
        setUserInteractions(campaignData.interactions || []);
      } else {
        console.log('No campaign data found for:', campaignKey);
        setUserCampaignData(null);
        setUserInteractions([]);
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsOptedIn(false);
      setUserCampaignData(null);
      setUserInteractions([]);
    }
  };

  useEffect(() => {
    console.log('useEffect triggered with:', { accountId, id });
    if (accountId && id) {
      fetchUserData();
    }
  }, [accountId, id]); // Add fetchUserData to dependencies if needed

  const handleClaim = async () => {
    if (!accountId || !campaign) {
      console.log('Missing required data:', { accountId, campaign });
      return;
    }

    setIsClaimLoading(true);
    setClaimError(null);

    try {
      console.log('Attempting to claim with:', { 
        accountId, 
        campaignId: campaign._id,
        userCampaignData,
        campaign
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${campaign._id}/claim`,
        { accountId }
      );

      console.log('Claim response:', response.data);
      setClaimTxId(response.data.transactionId);
      await fetchUserData();
      
    } catch (error: any) {
      console.error('Claim error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        error: error.response?.data?.error
      });
      setClaimError(error.response?.data?.error || 'Failed to claim rewards');
    } finally {
      setIsClaimLoading(false);
    }
  };

  const fetchLeaderboard = async (page: number = 1) => {
    if (!id) return;
    
    try {
      setIsLoadingMore(true);
      console.log('Fetching leaderboard for page:', page);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/leaderboard`,
        { params: { page, limit: 20 } }
      );
      
      console.log('Leaderboard response:', response.data);
      
      if (page === 1) {
        setLeaderboard(response.data.leaderboard);
      } else {
        setLeaderboard(prev => [...prev, ...response.data.leaderboard]);
      }
      
      console.log('Updated leaderboard state:', response.data.leaderboard);
      setTotalPages(response.data.pagination.pages);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadMore = () => {
    if (leaderboardPage < totalPages) {
      setLeaderboardPage(prev => prev + 1);
      fetchLeaderboard(leaderboardPage + 1);
    }
  };

  useEffect(() => {
    const checkClaimStatus = () => {
      console.log('Starting claim status check:', {
        hasCampaign: !!campaign,
        hasAccount: !!accountId,
        hasUserData: !!userCampaignData,
        isOptedIn,
        claimStatus: userCampaignData?.claimStatus
      });

      if (!campaign || !accountId || !userCampaignData || !isOptedIn) {
        console.log('Claim check failed due to:', {
          noCampaign: !campaign,
          noAccount: !accountId,
          noUserData: !userCampaignData,
          notOptedIn: !isOptedIn
        });
        setIsClaimable(false);
        return;
      }

      const endTimeDate = typeof campaign.endTime === 'string' 
        ? new Date(campaign.endTime)
        : new Date(parseInt(campaign.endTime.$date.$numberLong));
      
      const hasEnded = endTimeDate < new Date();
      const notYetClaimed = userCampaignData.claimStatus === 'unclaimed';
      const claimingEnabled = campaign.claimAvailable === true;
      
      const canClaim = hasEnded && notYetClaimed && claimingEnabled && isOptedIn;
      
      console.log('Claim eligibility check:', {
        hasEnded,
        notYetClaimed,
        claimingEnabled,
        isOptedIn,
        canClaim,
        endTimeDate,
        now: new Date(),
        claimStatus: userCampaignData.claimStatus
      });
      
      setIsClaimable(canClaim);
    };

    checkClaimStatus();
  }, [campaign, accountId, userCampaignData, isOptedIn]);

  const handleShare = () => {
    const tweetText = `Check out this campaign: ${campaign?.name} on @WeTheMouth`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };

  const handleCreatorClick = (twitterHandle: string) => {
    window.open(`https://twitter.com/${twitterHandle.replace('@', '')}`, '_blank');
  };

  const handleOptIn = async () => {
    try {
      if (!accountId) {
        console.error('No wallet connected');
        return;
      }

      console.log('Sending opt-in request:', { 
        accountId, 
        campaignId: id,
        url: `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/opt-in`
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/campaigns/${id}/opt-in`,
        { accountId },
        { 
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true 
        }
      );
      
      console.log('Opt-in response:', response.data);
      
      // Set isOptedIn to true immediately
      setIsOptedIn(true);
      
      // Then refresh user data
      await fetchUserData();
      
    } catch (error: any) {
      console.error('Error opting in to campaign:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
    }
  };

  useEffect(() => {
    console.log('Fetching initial leaderboard data');
    fetchLeaderboard(1);
  }, [id]); // Add id as dependency

  if (loading) {
    return <CircularProgress />;
  }

  if (!campaign) {
    return <Typography color="#ff004e">Campaign not found</Typography>;
  }

  return (
    <Box sx={{
      backgroundImage: `url(${serBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flex: 1 }}>
        <Paper elevation={3} sx={{ p: 3, backgroundColor: 'white', border: '1px solid #ff004e', borderRadius: '8px' }}>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon sx={{ color: '#ff004e' }} />}
            sx={{
              color: '#ff004e',
              mb: 2,
              textDecoration: 'none',
              padding: '0 0 2px 0',
              '&:hover': { backgroundColor: 'transparent' },
              '& .MuiButton-startIcon': { marginRight: 0.5 },
              '&::after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 1,
                height: '1px',
                backgroundColor: '#ff004e',
              },
            }}
          >
            BACK
          </Button>



          <Grid container spacing={3}>
            {/* Left Column - Scoreboard */}
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                    {campaign.name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#ff004e' }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        mr: 1, 
                        cursor: 'pointer', 
                        '&:hover': { textDecoration: 'underline' } 
                      }}
                      onClick={() => handleCreatorClick(campaign.creatorTwitterHandle)}
                    >
                      Created by {campaign.creatorTwitterHandle}
                    </Typography>
                    <Typography variant="body2" sx={{ mx: 1 }}>|</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <NetworkLogo 
                        src={`/network-logos/${campaign.network.toLowerCase()}.png`} 
                        alt={campaign.network} 
                      />
                      <Typography variant="body2">{campaign.network}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                    {getTimeLeft(campaign.endTime)}
                  </Typography>
                  <Button 
                    variant="outlined" 
                    startIcon={<ShareIcon />} 
                    onClick={handleShare}
                    sx={{ 
                      color: '#ff004e', 
                      borderColor: '#ff004e',
                      borderRadius: '20px',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 78, 0.04)',
                        borderColor: '#ff004e',
                      }
                    }}
                  >
                    SHARE
                  </Button>

                </Box>
              </Box>
              {accountId && (
            <Box sx={{ mt: 2, mb: 3 }}>
              <Button
                variant="contained"
                onClick={handleOptIn}
                disabled={isOptedIn}
                sx={{
                  backgroundColor: isOptedIn ? 'grey.500' : '#ff004e',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: isOptedIn ? 'grey.600' : '#ff0066',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'grey.400',
                    color: 'grey.100',
                  }
                }}
              >
                {isOptedIn ? 'Opted In' : 'Opt In'}
              </Button>
              {isOptedIn && (
                <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'grey.600' }}>
                  You're already opted in to this campaign
                </Typography>
              )}
            </Box>
          )}
              <Box sx={{ mt: 4 }}>
        {campaign.content?.tweets && (
          <>
            {console.log('Passing to CampaignTweets:', {
              tweets: campaign.content.tweets,
              userInteractions,
              accountId
            })}
            <CampaignTweets 
              tweets={campaign.content.tweets}
              userInteractions={userInteractions}
              accountId={accountId}
            />
          </>
        )}
      </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                  SCOREBOARD
                </Typography>
                
                <TableContainer component={Paper} sx={{ 
                  mb: 2, 
                  boxShadow: 'none', // Remove paper shadow
                  '& .MuiTable-root': {
                    borderSpacing: '0', // Reduce space between rows
                  }
                }}>
                  <Table size="small"> {/* Add size="small" for more compact rows */}
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell>RANK</StyledTableHeaderCell>
                        <StyledTableHeaderCell>USER</StyledTableHeaderCell>
                        <StyledTableHeaderCell>SCORE</StyledTableHeaderCell>
                        <StyledTableHeaderCell>MULTIPLIER</StyledTableHeaderCell>
                        <StyledTableHeaderCell>CURRENT PRIZE</StyledTableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leaderboard.map((entry, index) => (
                        <TableRow 
                          key={entry.accountId}
                          sx={{ 
                            backgroundColor: entry.accountId === accountId ? 'rgba(255, 0, 78, 0.04)' : 'inherit',
                            '&:hover': { backgroundColor: 'rgba(255, 0, 78, 0.04)' }
                          }}
                        >
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{entry.displayName}</StyledTableCell>
                          <StyledTableCell>{Math.round(entry.score)}</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                          <StyledTableCell>-</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                
              </Box>
            </Grid>

            {/* Right Column - Campaign Details */}
            <Grid item xs={12} md={4}>
              {/* <Paper elevation={2} sx={{ p: 2, mb: 3, border: '1px solid #ff004e', borderRadius: '8px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                  TOKEN GATING
                </Typography>
                <List>
                  {campaign.tokenGating.map((req, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={`${req.name} (${req.type})`}
                        secondary={`${req.amount} required - Token ID: ${req.tokenId}`}
                        primaryTypographyProps={{ color: '#ff004e' }}
                        secondaryTypographyProps={{ color: '#ff004e' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper> */}


              <Paper elevation={2} sx={{ p: 2, mb: 3, border: '1px solid #ff004e', borderRadius: '8px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                  PRIZE POT
                </Typography>
                <List>
                  {campaign.prizePot.map((prize, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={`${prize.amount} ${prize.name}`}
                        secondary={`Type: ${prize.type} - Token ID: ${prize.tokenId}`}
                        primaryTypographyProps={{ color: '#ff004e' }}
                        secondaryTypographyProps={{ color: '#ff004e' }}
                      />
                    </ListItem>
                  ))}
                </List>

                {accountId && (
                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="contained" 
                      onClick={handleClaim}
                      disabled={!isClaimable || isClaimLoading}
                      fullWidth
                      sx={{ 
                        mt: 2,
                        backgroundColor: isClaimable && !isClaimLoading ? '#ff004e' : '#ccc',
                        '&:hover': {
                          backgroundColor: isClaimable && !isClaimLoading ? '#d3003f' : '#ccc',
                        },
                        '&:disabled': {
                          backgroundColor: '#ccc',
                          color: 'white',
                          cursor: 'not-allowed'
                        }
                      }}
                    >
                      {isClaimLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : userCampaignData?.claimStatus === 'claimed' ? (
                        `${userCampaignData.rewardAmount} MOUTH Claimed`
                      ) : !isClaimable ? (
                        'Claim Not Available'
                      ) : userCampaignData?.rewardAmount ? (
                        `Claim ${userCampaignData.rewardAmount} MOUTH`
                      ) : (
                        'Claim Rewards'
                      )}
                    </Button>
                    {userCampaignData?.claimStatus === 'claimed' && userCampaignData.claimTxId && (
                      <Typography variant="caption" sx={{ display: 'block', mt: 1, color: 'grey.600', wordBreak: 'break-all' }}>
                        TX: {userCampaignData.claimTxId}
                      </Typography>
                    )}
                    {claimError && (
                      <Typography color="error" variant="caption" sx={{ display: 'block', mt: 1 }}>
                        {claimError}
                      </Typography>
                    )}
                  </Box>
                )}
              </Paper>

              <Paper elevation={2} sx={{ p: 2, mb: 3, border: '1px solid #ff004e', borderRadius: '8px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff004e', mb: 1 }}>
                  MULTIPLIERS
                </Typography>
                <List>
                  {campaign.multipliers.map((multiplier, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={`${multiplier.name} (${multiplier.type})`}
                        secondary={`${multiplier.amount} required - Multiplier: ${multiplier.multiplier}x`}
                        primaryTypographyProps={{ color: '#ff004e' }}
                        secondaryTypographyProps={{ color: '#ff004e' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>

    </Box>
  );
};

export default CampaignDetails;
