import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Box, FormControl, Select, MenuItem, Chip, ToggleButtonGroup, ToggleButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, SelectChangeEvent, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Campaign } from '../types/Campaign';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import { getTimeLeft } from '../utils/timeUtils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const TabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '-1px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
  }
}));

const StyledTab = styled(Box)<{ active: boolean }>(({ active, theme }) => ({
  padding: '10px 20px',
  cursor: 'pointer',
  backgroundColor: active ? 'white' : '#ff004e',
  color: active ? '#ff004e' : 'white',
  border: '1px solid #ff004e',
  borderBottom: active ? 'none' : '1px solid #ff004e',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  marginRight: '5px',
  fontWeight: 'bold',
  position: 'relative',
  zIndex: active ? 1 : 0,
  fontSize: '14px',
  flex: 1,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    padding: '8px 12px',
    fontSize: '12px',
    marginRight: '2px',
  },
  '&:hover': {
    backgroundColor: active ? 'white' : 'white',
    color: active ? '#ff004e' : '#ff004e',
  },
  '&::after': active ? {
    content: '""',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    height: '2px',
    backgroundColor: 'white',
  } : {},
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: '1px solid #ff004e',
  borderRadius: '10px',
  overflow: 'hidden',
  backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '8px',
  },
  '& .MuiToggleButtonGroup-grouped': {
    margin: 1,
    border: 'none',
    borderRadius: '10px',
    padding: '5px 10px',
    color: '#ff004e',
    '&.Mui-selected': {
      backgroundColor: '#ff004e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff004e',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: '10px',
    },
    '&:first-of-type': {
      borderRadius: '10px',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  '&.MuiToggleButton-root': {
    color: '#ff004e',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 78, 0.04)',
    },
  },
});

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '& .MuiSelect-select': {
    color: '#ff004e',
    padding: '5px 32px 5px 10px', // Reduced vertical padding
  },
  '& .MuiSvgIcon-root': {
    color: '#ff004e',
  },
});

const StyledTableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  // backgroundColor: '#ff004e',
  color: '#ff004e',
  borderBottom: '1px solid lightgray',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid lightgrey',
  '&:last-child': {
    borderRight: 'none',
  },
  color: '#ff004e',
  fontWeight: 'bold',
}));

const LogoContainer = styled(Box)({
  width: '32px',
  height: '32px',
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  flexShrink: 0,
});

const CampaignLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: '50%',
});

const NetworkLogo = styled('img')({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  objectFit: 'contain',
  marginRight: 5,
  verticalAlign: 'middle',
  flexShrink: 0,
});

const StyledMenuItem = styled(MenuItem)({
  color: '#ff004e',
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 78, 0.04)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 0, 78, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 78, 0.12)',
    },
  },
});

const CampaignTable: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('ALL CAMPAIGNS');
  const [network, setNetwork] = useState<string>('All Networks');
  const [prize, setPrize] = useState<string>('All Tokens');
  const [campaignType, setCampaignType] = useState<string>('all');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [optedInCampaignIds, setOptedInCampaignIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const { accountId } = useWalletInterface();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const campaignsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns`);
        setCampaigns(campaignsResponse.data);

        if (accountId) {
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${accountId}`);
          setOptedInCampaignIds(userResponse.data.optedInCampaigns || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [accountId]);

  const getFilteredCampaigns = () => {
    return campaigns.filter(campaign => {
      const networkMatch = network === 'All Networks' || campaign.network === network;
      const prizeMatch = prize === 'All Tokens' || 
        campaign.prizePot.some(p => 
          p.name === prize || 
          (prize === 'NFT' && p.type === 'nft') ||
          (prize === 'HBAR' && p.name === 'HBAR')
        );
      const typeMatch = campaignType === 'all' || campaign.type.toLowerCase() === campaignType;
      const participatingMatch = activeTab !== 'PARTICIPATING' || optedInCampaignIds.includes(campaign._id);
      
      return networkMatch && prizeMatch && typeMatch && participatingMatch;
    });
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleNetworkChange = (event: SelectChangeEvent<unknown>) => {
    setNetwork(event.target.value as string);
  };

  const handlePrizeChange = (event: SelectChangeEvent<unknown>) => {
    setPrize(event.target.value as string);
  };

  const handleCampaignTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newCampaignType: string | null,
  ) => {
    if (newCampaignType !== null) {
      setCampaignType(newCampaignType);
    }
  };

  const handleRowClick = (campaignId: string) => {
    navigate(`/campaign/${campaignId}`);
  };

  const filteredCampaigns = getFilteredCampaigns();

  return (
    <Box>
      <TabContainer>
        <StyledTab 
          active={activeTab === 'ALL CAMPAIGNS'} 
          onClick={() => handleTabChange('ALL CAMPAIGNS')}
        >
          ALL
        </StyledTab>
        <StyledTab 
          active={activeTab === 'ELIGIBLE'} 
          onClick={() => handleTabChange('ELIGIBLE')}
        >
          ELIGIBLE
        </StyledTab>
        <StyledTab 
          active={activeTab === 'PARTICIPATING'} 
          onClick={() => handleTabChange('PARTICIPATING')}
        >
          PARTICIPATING
        </StyledTab>
      </TabContainer>
      <Paper sx={{ 
        p: 3, 
        backgroundColor: 'white', 
        border: '1px solid #ff004e', 
        borderRadius: '8px', 
        borderTopLeftRadius: 0,
        color: '#ff004e',
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          mb: 2,
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 1 : 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            width: isMobile ? '100%' : 'auto',
            justifyContent: 'space-between'
          }}>
            <FormControl sx={{ minWidth: 120 }}>
              <StyledSelect
                value={network}
                onChange={handleNetworkChange}
                displayEmpty
                renderValue={(value) => value as string}
              >
                <StyledMenuItem value="All Networks">All Networks</StyledMenuItem>
                <StyledMenuItem value="Hedera">Hedera</StyledMenuItem>
                {/* <StyledMenuItem value="Ethereum">Ethereum</StyledMenuItem> */}
              </StyledSelect>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <StyledSelect
                value={prize}
                onChange={handlePrizeChange}
                displayEmpty
                renderValue={(value) => value as string}
              >
                <StyledMenuItem value="All Tokens">All Tokens</StyledMenuItem>
                <StyledMenuItem value="HBAR">HBAR</StyledMenuItem>
                {/* <StyledMenuItem value="ETH">ETH</StyledMenuItem> */}
                <StyledMenuItem value="NFT">NFT</StyledMenuItem>
              </StyledSelect>
            </FormControl>
          </Box>

          <StyledToggleButtonGroup
            value={campaignType}
            exclusive
            onChange={handleCampaignTypeChange}
            aria-label="campaign type"
            sx={{
              width: isMobile ? '100%' : 'auto',
              justifyContent: isMobile ? 'space-between' : 'flex-start'
            }}
          >
            <StyledToggleButton value="all">All</StyledToggleButton>
            <StyledToggleButton value="draw">Draw</StyledToggleButton>
            <StyledToggleButton value="share">Share</StyledToggleButton>
            <StyledToggleButton value="rank">Rank</StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>CAMPAIGN</StyledTableCell>
                <StyledTableCell>TYPE</StyledTableCell>
                <StyledTableCell>TIME LEFT</StyledTableCell>
                <StyledTableCell>PRIZE POT</StyledTableCell>
                {/* <StyledTableCell>TOKEN GATED</StyledTableCell> */}
                <StyledTableCell>CHANNELS</StyledTableCell>
                <StyledTableCell>PARTICIPANTS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCampaigns.map((campaign) => (
                <TableRow 
                  key={campaign._id} 
                  onClick={() => handleRowClick(campaign._id)}
                  sx={{ 
                    cursor: 'pointer', 
                    '&:hover': { backgroundColor: 'rgba(255, 0, 78, 0.04)' },
                  }}
                >
                  <StyledTableCell>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1,
                    }}>
                      <LogoContainer>
                        <CampaignLogo 
                          src={`/campaign-logos/${campaign._id}.png`} 
                          onError={(e) => {
                            e.currentTarget.src = "/campaign-logos/default.png";
                          }}
                          alt={campaign.name} 
                        />
                      </LogoContainer>
                      <Box>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                            marginBottom: '2px'
                          }}
                        >
                          {campaign.name}
                        </Typography>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center'
                        }}>
                          <NetworkLogo 
                            src={`/network-logos/${campaign.network.toLowerCase()}.png`} 
                            alt={campaign.network} 
                          />
                          <Typography 
                            variant="body2"
                            sx={{ 
                              fontSize: '0.875rem',
                              color: 'inherit'
                            }}
                          >
                            {campaign.network}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{campaign.type}</StyledTableCell>
                  <StyledTableCell>{getTimeLeft(campaign.endTime)}</StyledTableCell>
                  <StyledTableCell>
                    {campaign.prizePot.map((prize, index) => (
                      <div key={index}>{`${prize.amount} ${prize.name}`}</div>
                    ))}
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    {campaign.tokenGating.length > 0 ? 'Yes' : 'No'}
                  </StyledTableCell> */}
                  <StyledTableCell>{campaign.channels.join(', ')}</StyledTableCell>
                  <StyledTableCell>{campaign.participants}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CampaignTable;
