import React from 'react';
import { Typography, Box, Paper, Grid } from '@mui/material';

const Tiles: React.FC = () => {
  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        Tiles
      </Typography>
      
      <Grid container spacing={3}>
        {[1, 2, 3, 4, 5, 6].map((tile) => (
          <Grid item xs={12} sm={6} md={4} key={tile}>
            <Paper sx={{ p: 2, height: '200px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <Typography variant="h6">Tile {tile}</Typography>
              <Typography>Content for Tile {tile}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Tiles;

